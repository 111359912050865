import React, { useState } from "react";
import GiftCard from "./GiftCard";

const renderComponent = (component, setComponent) => {
    switch (component) {
        case 'REVEALED':
            return <GiftCard />;
        case 'GIFT':
        default:
            return (
                <img alt="present" src='/images/present.png' width="100%" 
                    onClick={() => setComponent('REVEALED')}
                />
            );
    }
}

const Home = () => {
    const [component, setComponent] = useState('GIFT');

    return (
        <div width="100%" 
            className="d-flex flex-column"
            >
            <div className="d-flex flex-column justify-content-center">
                <div className="w-100 toLabel">
                    <h3>Til</h3>
                    <h2 className="display-4">Ann-Lene</h2>
                </div>
                <div>
                    {renderComponent(component, setComponent)}
                </div>
                <div className="w-100 fromLabel">
                    <h3>Fra</h3>
                    <h2 className="display-4">Even</h2>
                </div>
            </div>
        </div>
    )
};

export default Home;