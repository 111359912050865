import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import ImageView from './ImageView';

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

const App = (props) => {
    return (
        <HashRouter>
            <React.Suspense fallback={loading}>
                <Switch>
                <Route path="/" name="Home" render={props => <ImageView {...props}/>} exact />
                    <Route path="/image/:hash" name="Image" render={props => <ImageView {...props}/>} exact />
                    <Route path="/*" name="Home" render={props => <Home {...props}/>} exact />
                </Switch>
            </React.Suspense>
        </HashRouter>
    )
};

export default App;