import React, { useState } from "react";

const flip = (event) => {
	var element = event.currentTarget;
    console.log("element:", element);
	if (element.className === "card") {
    if(element.style.transform === "rotateY(180deg)") {
      element.style.transform = "rotateY(0deg)";
    }
    else {
      element.style.transform = "rotateY(180deg)";
    }
  }
};

const GiftCard = (props) => {
    const [showBack, setShowBack] = useState(false);
    return (
        <div className="d-flex justify-content-center">
            <div className={`giftCard text-center ${showBack ? ' d-none ' : ''}`} onClick={(e) => setShowBack(true)}>
                <h1 className="pt-3">Gavekort</h1>
                <img alt="logo" className="w-100 fr-logo" src="/images/fr_logo.png" />
                <h4 className="fr-logo">fjellrevenshop.no</h4>
            </div>
            <div className={`giftCardBack text-center ${!showBack ? ' d-none ' : ''}`} onClick={() => setShowBack(false)}>
                <h1 className="pt-3">Verdi</h1>
                <h1 className="mt-5 mb-2" style={{fontSize: '3rem', height: '100px'}}>Kr. 2000,-</h1>
                <h4>fjellrevenshop.no</h4>
            </div>
        </div>
    )
}

export default GiftCard;