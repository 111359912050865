import React from "react";
import Home from "./Home";


const imgFromHash = (hash) => {
    console.log("looking for hash:", hash);
    switch(hash) {
        case '7335f693-88da-4a93-b6bb-0ed956d0e7b1':
            return "1-ny.jpeg";
        case '9c0b3181-c80b-49c5-9705-d58734c228df':
            return "2-ny.jpeg";
        case '796267d0-4419-4bd1-9575-8a5d11a71f6f':
            return "3-ny.jpeg";
        case 'ad62ca4d-e48d-4674-be22-45afccbf1207':
            return "4-ny.jpeg";
        default:
            return "santa2.png";
    }

}

const ImageView = (props) => {
    console.log(props.match.params.hash);
    if (props.match.params.hash === '47d45e9d-bc63-4034-b135-87586bb82918') {
        return (
            <Home />
        )
    }
    return (
        <div className="d-flex justify-content-center">
            <img alt="hint" className="" src={`/images/${imgFromHash(props.match.params.hash)}`} />
        </div>
    )
};

export default ImageView;